// Import dependencies
import axios from 'axios';

// Import messages
import { SignMessages } from '../messages/signMessages.js';

// Sets the environment variables
const API_URL = process.env.REACT_APP_API_URL;

// Function for user authentication
export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/auth`, { email, password });

        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('aboutUser', JSON.stringify(response.data.customerData));

        return response.data.user;
    } catch (error) {
        const message = error.response?.data?.message || SignMessages.GENERIC.INTERNAL_ERROR;
        throw new Error(message);
    }
};

// Function for user validate token
export const validateToken = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error(SignMessages.TOKEN.UNDEFINED);
    } else {
        try {
            const response = await axios.post(`${API_URL}/validate-token`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });

            return response.data.user;
        } catch (error) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('aboutUser');
            throw new Error(SignMessages.TOKEN.EXPIRED);
        }
    }
};
