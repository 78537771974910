// Import react
import React, { useState } from 'react';

// Import CSS
import './authInput.scss'

// Import icons
import { Eye, EyeOff } from '../../icons/Eye';

const AuthInput = ({ icon, label, type, name, value, onChange, placeholder }) => {
    // Treatment for component states
    const [isPasswordVisible, setPasswordVisibility] = useState(false);

    // Hides and displays the user's password
    const togglePasswordVisibility = () => {
        setPasswordVisibility(!isPasswordVisible);
    };

    return (
        <div className="form-input">
            <label className="input-title">{label}</label>
            <div className="input-wrapper">
                <span className="input-icon">{icon}</span>
                {type === "password" && (
                    <button
                        type="button"
                        className="show-and-hide-password"
                        onClick={togglePasswordVisibility}
                        aria-label={isPasswordVisible ? "Esconder senha" : "Mostrar senha"}
                    >
                        {isPasswordVisible ? <Eye width={24} height={24} /> : <EyeOff width={24} height={24} />}
                    </button>
                )}
                <input
                    className="input-data"
                    type={isPasswordVisible ? "text" : type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
                <span className="input-focus"></span>
            </div>
        </div>
    )
};

export default AuthInput;