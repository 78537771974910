export const convertToReal = (value) => {
    const decimalValue = parseFloat(value);

    if (isNaN(decimalValue)) {
        return '';
    }

    return decimalValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
};

export const convertToDecimal = (value) => {
    const sanitizedValue = value.replace(/[^\d,.-]/g, "").replace(/\./g, "");
    const decimalValue = sanitizedValue.replace(",", ".");

    return parseFloat(decimalValue);
};

export const convertDateToBrazilian = (dateString) => {
    const [year, month, day] = dateString.split('-');

    return `${day}/${month}/${year}`;
}