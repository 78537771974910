// Import react
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import dependencies
import { clsx } from 'clsx';

// Import CSS
import '../styles/routes/listService.scss'

// Import components
import Navbar from '../components/Navbar/Navbar';
import ModalOfActionOrFeedback from '../components/ModalOfActionOrFeedback/ModalOfActionOrFeedback.jsx';

// Import messages and data
import { NavbarPathnames } from '../data/menu.js';
import { ServicesMessages } from '../messages/servicesMessages.js';

// Import icons
import { EditIcon, TrashIcon } from '../icons/Common';
import { FeedbackInfo } from '../icons/Feedback.jsx';

// Import functions
import { convertToReal } from '../functions/converts';

// Import services
import { deleteSelectedService, getListService } from '../services/manageServices.js'

// Import hooks
import useGlobalState from '../hooks/useGlobalState';

function ListService() {
    // Defines constants to be used
    const navigate = useNavigate();
    const { aboutUser, isNavbarExpanded, toggleNavbar } = useGlobalState()

    // Treatment for list service state
    const [listService, setListService] = useState({
        aboutUser: {
            img: '',
            name: '',
            jobTitle: ''
        },
        aboutOurServices: {
            services: [],
            length: []
        },
        serviceForDelete: {
            about: null,
            deleting: {
                about: null,
                status: false,
                message: '',
                error: {
                    status: false,
                    message: ''
                }
            },
            deleted: {
                about: null,
                status: false,
                message: '',
                error: {
                    status: false,
                    message: ''
                }
            }
        }
    });

    // Load the list of services
    const fetchData = async () => {
        let isMounted = true;

        try {
            const [listService] = await Promise.all([
                getListService()
            ]);

            if (isMounted) {
                setListService((prev) => ({
                    ...prev,
                    aboutOurServices: {
                        services: listService,
                        length: (
                            listService.length > 0
                                ? `Foram encontrado(s) ${listService.length} serviço(s) cadastrados.`
                                : 'Não foram encontrados serviços cadastrados.'
                        )
                    }
                }));
            }
        } catch (error) {
            if (isMounted) {
                console.error('Erro ao obter dados: ', error.message);
            }
        }

        return () => {
            isMounted = false;
        };
    };

    // Load the list of services
    useEffect(() => {
        fetchData();
    }, []);

    // Delete the service
    const deleteService = async () => {
        try {
            await deleteSelectedService(listService.serviceForDelete.deleting.about.id)

            setListService((prev) => ({
                ...prev,
                serviceForDelete: {
                    ...prev.serviceForDelete,
                    deleting: {
                        ...prev.serviceForDelete.deleting,
                        status: false
                    },
                    deleted: {
                        ...prev.serviceForDelete.deleted,
                        status: true,
                        message: `O serviço ${listService.serviceForDelete.deleting.about.type} foi deletado com sucesso!`
                    }
                }
            }));

            fetchData();
        } catch (error) {
            setListService((prev) => ({
                ...prev,
                serviceForDelete: {
                    ...prev.serviceForDelete,
                    deleting: {
                        status: false
                    },
                    deleted: {
                        ...prev.serviceForDelete.deleted,
                        error: {
                            status: true,
                            message: ServicesMessages.GENERIC.INTERNAL_ERROR
                        }
                    }
                }
            }));
        }
    }

    // Confirmation of exclusion from the service
    const confirmDelete = (service) => {
        setListService((prev) => ({
            ...prev,
            serviceForDelete: {
                ...prev.serviceForDelete,
                deleting: {
                    about: service,
                    status: true,
                    message: `Você está excluindo o serviço ${service.type}.`
                }
            }
        }));
    };

    // Closes the feedback modal for the service exclusion flow
    const closeFeedbackModalForDelete = () => {
        setListService((prev) => ({
            ...prev,
            serviceForDelete: {
                ...prev.serviceForDelete,
                deleting: {
                    status: false
                },
                deleted: {
                    status: false
                }
            }
        }));
    };

    // Saves the serviceId for editing the service
    const editService = async (service) => {
        localStorage.setItem('serviceForEdit', service.id);
        navigate(NavbarPathnames.editService);
    };

    return (
        <section id="home" className={clsx("global-content", { expanded: isNavbarExpanded, collapsed: !isNavbarExpanded })}>
            <Navbar userInfo={aboutUser} isExpanded={isNavbarExpanded} toggleNavbar={toggleNavbar} />

            <main id="content">
                <header className="content-title">
                    <h2>Gerenciar Serviços</h2>
                    <h3>Edite e delete informações dos serviços cadastrados</h3>
                </header>

                <div className="content-data data-for-list-services">
                    <section id="list-services">
                        <button id="register-service" onClick={() => navigate(NavbarPathnames.registerService)}>Cadastrar novo serviço</button>

                        <table>
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                    <th>Duração</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listService.aboutOurServices.services.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.type}</td>
                                        <td>{item.description}</td>
                                        <td>{convertToReal(item.value)}</td>
                                        <td>{`${item.duration} minuto(s)`}</td>
                                        <td className="table-actions">
                                            <EditIcon width={'18px'} height={'18px'} onClick={() => editService(item)} />
                                            <TrashIcon width={'18px'} height={'18px'} onClick={() => confirmDelete(item)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <h2 className="about-registered-services">{listService.aboutOurServices.length}</h2>
                    </section>
                </div>
            </main>

            <ModalOfActionOrFeedback
                conditionToBeVisible={listService.serviceForDelete.deleting.status}
                typeOfModal={{ type: "action", colorFor: "delete" }}

                data={{
                    icon: <TrashIcon width={'28px'} height={'28px'} color={'#FFF'} />,
                    title: "Deseja confirmar a exclusão?",
                    message: listService.serviceForDelete.deleting.message
                }}

                buttons={{
                    confirm: "confirmar",
                    onClickConfirm: deleteService,
                    cancel: "cancelar",
                    onClickCancel: closeFeedbackModalForDelete
                }}
            />

            <ModalOfActionOrFeedback
                conditionToBeVisible={listService.serviceForDelete.deleted.status}
                typeOfModal={{ type: "feedback", colorFor: "info" }}

                data={{
                    icon: <FeedbackInfo width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: listService.serviceForDelete.deleted.message
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeFeedbackModalForDelete }}
            />
        </section>
    );
}

export default ListService;