// Import react
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Import CSS
import './navbarCollapsed.scss'

const NavbarCollapsedOptionExpandable = ({ optionIcon, optionName, subMenuOptions, toggleName }) => {
    // Defines constants to be used
    const navigate = useNavigate();

    return (
        <>
            <nav className="navbar-collapsed-item">
                <div className="navbar-icon-collapsed">{optionIcon}</div>
                <ol className="navbar-option-collapsed-expandable">
                    <li className="navbar-options-collapsed-title">{optionName}</li>
                    {subMenuOptions.map((option, index) => (
                        <li key={index} className="navbar-submenu-item-collapsed" onClick={() => navigate(option.optionNavigate)}>
                            <span className="navbar-text">{option.optionName}</span>
                        </li>
                    ))}
                </ol>
            </nav>
        </>
    )
};

export default NavbarCollapsedOptionExpandable;