// Import react
import React from 'react';

// Import dependencies
import { clsx } from 'clsx';

// Import CSS
import './signMessage.scss'

const SignMessage = ({ condition, type, icon, message}) => {
    return (
        <>
            {condition && (
                <div className={clsx("sign-message", type)}>
                    <span>{icon}</span>
                    {message}
                </div>
            )}
        </>
    )
};

export default SignMessage;