export const SignMessages = {
    GENERIC: {
        INTERNAL_ERROR: "Ops! Houve um problema em nosso servidores, tente novamente."
    },
    TOKEN: {
        UNDEFINED: "Sessão não encontrada. Por favor, faça login novamente.",
        EXPIRED: "Sua sessão expirou. Por segurança, faça login novamente."
    },
    REQUIRED_FIELDS: {
        NAME: "O campo nome é obrigatório",
        EMAIL: "O campo e-mail é obrigatório",
        PASSWORD: "O campo senha é obrigatório",
        CONFIRM_PASSWORD: "O campo confirmar senha é obrigatório"
    },
    INVALID_FILLING: {
        NAME: "O campo nome deve conter no mínimo 3 caracteres",
        EMAIL: "O e-mail informado é inválido",
        PASSWORD: "Sua senha deve conter no mínimo 6 caracteres",
        MIS_MATCH_PASSWORD: "As senhas informadas não se coincidem"
    },
    SUCCESS_SEND_RESET_PASSWORD: {
        TITLE: "Enviamos um e-mail com instruções para você redefinir a sua senha.",
        MESSAGE: "Confira sua caixa de entrada. Caso o e-mail não esteja lá, verifique também a pasta de spam. Fique atento, seu link expira em 15 minutos."
    },
    SUCCESS_RESET_PASSWORD: {
        TITLE: "Senha alterada com sucesso.",
        MESSAGE: "Lembre-se de usar a nova senha para seu próximo acesso ao sistema."
    },
    ERROR_RESET_PASSWORD: {
        TITLE: "Ops! Link de redefinição inválido."
    },
    SUCCESS_SIGN_UP: {
        TITLE: "Usuário cadastrado com sucesso.",
        MESSAGE: "Utilize os dados informados para acessar o sistema."
    }
}