// Import react
import React, { useRef } from 'react';

// Import dependencies
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

// Import SCSS
import './cropImage.scss'

const ImageCropper = ({ image, onCrop }) => {
    const cropperRef = useRef(null);

    const handleCrop = () => {
        const cropper = cropperRef.current.cropper;
        const croppedCanvas = cropper.getCroppedCanvas({
            width: 180,
            height: 220,
        });

        croppedCanvas.toBlob((blob) => {
            if (onCrop) onCrop(blob);
        }, 'image/png', 1);
    };

    return (
        <div className="content-crop-image">
            <Cropper
                className="custom-cropper"
                src={image}
                guides={false}
                ref={cropperRef}
                aspectRatio={450 / 550}
                initialAspectRatio={450 / 550}
                style={{ height: 'auto', width: '400px' }}
            />

            <button id="btn-crop-image" type="button" onClick={handleCrop}>Recortar</button>
        </div>
    );
};

export default ImageCropper;
