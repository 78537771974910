// Import react
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

// Import services
import { validateToken } from '../../services/auth';

const PrivateRoute = ({ element }) => {
    // Defines constants to be used
    const navigate = useNavigate();

    // Treatment for component states
    const isMounted = useRef(true);
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                await validateToken();
                if (isMounted.current) setIsAuthenticated(true);
            } catch (error) {
                if (isMounted.current) setIsAuthenticated(false);
                navigate('/');
            }
        };

        checkAuthentication();

        return () => {
            isMounted.current = false;
        };
    }, [navigate]);

    if (isAuthenticated === null) {
        return
    }

    return isAuthenticated ? element : <Navigate to="/" />;
};

export default PrivateRoute;