// Import react
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Import dependencies
import { clsx } from 'clsx';

// Import CSS
import './SignFeedback.scss'

const SignFeedback = ({ condition, feedback, icon, title, message, button, onClose, redirect }) => {
    // Defines constants to be used
    const navigate = useNavigate();

    // Defines if or not the user will be redirected after closing the feedback modal
    const handleButtonClick = () => {
        if (redirect) {
            navigate(redirect);
        }

        onClose();
    };

    return (
        <>
            {condition && (
                <div className="feedback-overlay">
                    <div className="feedback">
                        <div className={clsx("feedback-header", `background-${feedback}`)}>{icon}</div>
                        <div className="feedback-content">
                            <div className="feedback-text">
                                <h2 className={clsx("feedback-title", `color-${feedback}`)}>{title}</h2>
                                <h3 className="feedback-message">{message}</h3>
                            </div>

                            <div className="feedback-buttons">
                                <button className={clsx("feedback-close", `background-${feedback}`)} onClick={handleButtonClick}>
                                    {button}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default SignFeedback;