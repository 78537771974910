// Import react
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import CSS
import './navbar.scss'

// Import icons
import { NavbarLessOptions, NavbarMoreOptions, NavbarSubmenu } from '../../icons/Navbar';

const NavbarOptionExpandable = ({ optionIcon, optionName, subMenuOptions, toggleName }) => {
    // Defines constants to be used
    const navigate = useNavigate();

    // Treatment for component states
    const [expandedItem, setExpandedItem] = useState(null);
    const toggleExpand = (item) => {
        setExpandedItem(expandedItem === item ? null : item);
    };

    return (
        <ul>
            <li className="navbar-item">
                <div onClick={() => toggleExpand(toggleName)} className="navbar-expandable">
                    <div className="navbar-expandable-item">
                        {optionIcon}
                        <span className="navbar-text">{optionName}</span>
                    </div>

                    <div style={{ height: '22px' }}>
                        {expandedItem === toggleName ? (
                            <NavbarLessOptions width={"22px"} height={"22px"} />
                        ) : (
                            <NavbarMoreOptions width={"22px"} height={"22px"} />
                        )}
                    </div>
                </div>
            </li>

            {expandedItem === toggleName && (
                <ul className="navbar-submenu">
                    {subMenuOptions.map((option, index) => (
                        <li
                            key={index}
                            className="navbar-submenu-item"
                            onClick={() => navigate(option.optionNavigate)}
                        >
                            <NavbarSubmenu width={"6px"} height={"6px"} color={"#A7ACB2"} />
                            <span className="navbar-text">{option.optionName}</span>
                        </li>
                    ))}
                </ul>
            )}
        </ul>
    )
};

export default NavbarOptionExpandable;