// Import react
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Import CSS
import './navbar.scss'

const NavbarOption = ({ optionIcon, optionName, optionPage }) => {
    // Defines constants to be used
    const navigate = useNavigate();

    return (
        <ul className="navbar-item" onClick={() => navigate(optionPage)}>
            {optionIcon}
            <span className="navbar-text">{optionName}</span>
        </ul>
    )
};

export default NavbarOption;