// Import react
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import dependencies
import axios from 'axios';
import { clsx } from 'clsx';

// Import CSS
import '../styles/form.scss'
import '../styles/routes/registerService.scss'

// Import components and pages
import Navbar from '../components/Navbar/Navbar';
import FormField from '../components/FormField/FormField';
import ModalOfActionOrFeedback from '../components/ModalOfActionOrFeedback/ModalOfActionOrFeedback';

// Import messages
import { SignMessages } from '../messages/signMessages';
import { ServicesMessages } from '../messages/servicesMessages';

// Import icons
import { Error, Success } from '../icons/Circle';
import { FeedbackError, FeedbackSuccess } from '../icons/Feedback';
import { convertToReal } from '../functions/converts';

// Import hooks
import useGlobalState from '../hooks/useGlobalState';
import { NavbarPathnames } from '../data/menu';

// Sets the environment variables
const API_URL = process.env.REACT_APP_API_URL;

function RegisterService() {
    // Defines constants to be used
    const navigate = useNavigate();
    const { aboutUser, isNavbarExpanded, toggleNavbar } = useGlobalState()

    // Function responsible for converting the value to decimal
    const convertToDecimal = (value) => {
        const sanitizedValue = value.replace(/[^\d,.-]/g, "").replace(/\./g, "");
        const decimalValue = sanitizedValue.replace(",", ".");

        return parseFloat(decimalValue);
    };

    // Treatment for component states
    const [formState, setFormState] = useState({
        formValues: {
            id: '',
            type: '',
            value: '',
            duration: '',
            description: ''
        },
        errors: {
            type: '',
            value: '',
            duration: '',
            description: ''
        },
        errorMessage: '',
        isError: false,
        isSuccess: false,
        isLoading: false
    });

    const { formValues, errors, isError, isSuccess, isLoading } = formState;

    // Searches for user data
    useEffect(() => {
        const fetchServiceData = async () => {
            const serviceId = localStorage.getItem('serviceForEdit');
            const token = localStorage.getItem('authToken');

            try {
                const response = await fetch(`${API_URL}/get-service/${serviceId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                });


                const data = await response.json();
                setFormState((prev) => ({
                    ...prev,
                    formValues: {
                        id: data.id || '',
                        type: data.type || '',
                        value: convertToReal(data.value) || '',
                        duration: data.duration || '',
                        description: data.description || '',
                    },
                }));
            } catch (error) {
                setFormState((prev) => ({ ...prev, error: ServicesMessages.GENERIC.INTERNAL_ERROR }));
            }
        };

        fetchServiceData();
    }, []);

    // Default body for headers
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    }

    // Verification of the information filled in by the user
    const validateField = (name, value) => {
        const validators = {
            type: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.TYPE_OF_SERVICE;
                return '';
            },
            value: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.VALUE;
                if (convertToDecimal(value) === 0) return ServicesMessages.REQUIRED_FIELDS.VALUE;
                return '';
            },
            duration: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.DURATION;
                return '';
            },
            description: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.DESCRIPTION;
                return '';
            },
        };

        return validators[name] ? validators[name]() : '';
    };

    // Verification of the information filled in by the user
    const handleValidation = (event) => {
        const { name, value } = event.target;

        setFormState((prev) => {
            const errorMessage = validateField(name, value);
            const updatedFormValues = { ...prev.formValues, [name]: value };
            const updatedErrors = { ...prev.errors, [name]: errorMessage };

            return {
                ...prev,
                formValues: updatedFormValues,
                errors: updatedErrors,
            };
        });
    };

    useEffect(() => {
        return () => {
            const token = localStorage.getItem('serviceForEdit');
            if (token) {
                localStorage.removeItem('serviceForEdit');
            }
        };
    }, []);

    // Register a new service
    const editService = async (event) => {
        event.preventDefault();

        setFormState((prev) => ({
            ...prev,
            isLoading: true
        }));

        const typeError = validateField('type', formValues.type);
        const valueError = validateField('value', formValues.value);
        const durationError = validateField('duration', formValues.duration);
        const descriptionError = validateField('description', formValues.description);

        if (typeError || valueError || durationError || descriptionError) {
            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                errors: {
                    ...prev.errors,
                    type: typeError,
                    value: valueError,
                    duration: durationError,
                    description: descriptionError,
                },
            }));

            return;
        }

        try {
            const request = {
                url: `${API_URL}/edit-service/${formValues.id}`,
                headers: headers,
                body: {
                    type: formValues.type,
                    value: convertToDecimal(formValues.value),
                    duration: formValues.duration,
                    description: formValues.description,
                }
            }

            await axios.put(request.url, request.body, request.headers)

            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                isSuccess: true
            }));
        } catch (error) {
            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                error: {
                    status: true,
                    message: ServicesMessages.GENERIC.INTERNAL_ERROR
                }
            }));
        }
    };

    // Closes the feedback modal
    const closeModal = () => {
        setFormState((prev) => ({
            ...prev,
            isError: false,
            isSuccess: false
        }));

        navigate(NavbarPathnames.listServices)
    };

    return (
        <section id="home" className={clsx("global-content", { expanded: isNavbarExpanded, collapsed: !isNavbarExpanded })}>
            <Navbar userInfo={aboutUser} isExpanded={isNavbarExpanded} toggleNavbar={toggleNavbar} />

            <main id="content">
                <header className="content-title">
                    <h2>Editar Serviço</h2>
                    <h3>Edite todas as informações necessárias para o serviço a ser oferecido para as suas clientes</h3>
                </header>

                <div className="content-data">
                    <form id="register-service" className="custom-form" onSubmit={editService}>
                        <section className="form-section">
                            <h2 className="form-section-title">Informações</h2>
                            <div className="form-inputs">
                                <FormField
                                    inputId="type"
                                    inputName="type"
                                    inputSize="size-480"
                                    inputType="text"
                                    inputLabel="Tipo de serviço"
                                    inputPlaceholder="Ex: Esmaltação em Gel nas Mãos"
                                    inputValue={formValues.type}
                                    inputOnChange={handleValidation}
                                    feedbackType={errors.type ? "error" : "success"}
                                    feedbackMessage={errors.type}
                                    feedbackIcon={errors.type ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <FormField
                                    inputCode="3"
                                    inputId="value"
                                    inputName="value"
                                    inputSize="size-220"
                                    inputType="text"
                                    inputLabel="Valor do serviço"
                                    inputPlaceholder="Valor cobrado pelo serviço"
                                    inputValue={formValues.value}
                                    inputOnChange={handleValidation}
                                    feedbackType={errors.value ? "error" : "success"}
                                    feedbackMessage={errors.value}
                                    feedbackIcon={errors.value ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <FormField
                                    inputId="duration"
                                    inputName="duration"
                                    inputSize="size-260"
                                    inputType="number"
                                    inputLabel="Duração do serviço"
                                    inputPlaceholder="Preencha o tempo em minutos"
                                    inputValue={formValues.duration}
                                    inputOnChange={handleValidation}
                                    feedbackType={errors.duration ? "error" : "success"}
                                    feedbackMessage={errors.duration}
                                    feedbackIcon={errors.duration ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <FormField
                                    inputId="description"
                                    inputName="description"
                                    inputSize="max-size"
                                    inputType="text"
                                    inputLabel="Descrição do serviço"
                                    inputPlaceholder="Ex: Secagem rápida e eficiente. Você sai pronta para curtir o seu dia!"
                                    inputMaxLength="120"
                                    inputValue={formValues.description}
                                    inputOnChange={handleValidation}
                                    feedbackType={errors.description ? "error" : "success"}
                                    feedbackMessage={errors.description}
                                    feedbackIcon={errors.description ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />
                            </div>
                        </section>

                        <section className="form-buttons">
                            <button type="submit" className="register-button" disabled={isLoading}>
                                {isLoading ? <div className="loading" /> : 'Editar serviço'}
                            </button>
                        </section>
                    </form>
                </div>
            </main>

            <ModalOfActionOrFeedback
                conditionToBeVisible={isSuccess}
                typeOfModal={{ type: "feedback", colorFor: "success" }}

                data={{
                    icon: <FeedbackSuccess width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: `Serviço atualizado com sucesso!`
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModal }}
            />

            <ModalOfActionOrFeedback
                conditionToBeVisible={isError}
                typeOfModal={{ type: "feedback", colorFor: "error" }}

                data={{
                    icon: <FeedbackError width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: SignMessages.GENERIC.INTERNAL_ERROR
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModal }}
            />
        </section>
    );
}

export default RegisterService;