// Import react
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Import dependencies
import axios from 'axios';

// Import components
import AuthInput from '../components/AuthInput/AuthInput';
import SignMessage from '../components/SignMessage/SignMessage';
import SignFeedback from '../components/SignFeedback/SignFeedback';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';

// Import services
import { validateToken } from '../services/auth';

// Import SVG icons
import { Mail } from '../icons/Auth';
import { Error } from '../icons/Circle';
import { FeedbackSuccess } from '../icons/Feedback';

// Import messages
import { SignMessages } from '../messages/signMessages';

function SendResetPassword() {
    // Defines constants to be used
    const navigate = useNavigate();

    // Treatment for component states
    const [formState, setFormState] = useState({
        email: '',
        errorMessage: '',
        isSuccess: false,
        isLoading: false,
        errors: { email: '' },
    });

    const { email, errorMessage, isSuccess, isLoading, errors } = formState;

    // Checking the user's session when accessing the recovery password screen
    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            validateToken(authToken)
                .then(() => navigate('/dashboard'))
                .catch(() => localStorage.removeItem('authToken'));
        }
    }, [navigate]);

    // Verification of the information filled in by the user
    const validateField = (name, value) => {
        const validators = {
            email: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.EMAIL;
                if (!/\S+@\S+\.\S+/.test(value)) return SignMessages.INVALID_FILLING.EMAIL;
                return '';
            },
        };
        return validators[name] ? validators[name]() : '';
    };

    // Verification of the information filled in by the user
    const handleValidation = (event) => {
        const { name, value } = event.target;

        setFormState((prev) => ({
            ...prev,
            [name]: value,
            errors: { ...prev.errors, [name]: validateField(name, value) },
            errorMessage: name === 'email' ? '' : prev.errorMessage,
        }));
    };

    // Modal to close feedback
    const handleCloseModal = () => {
        setFormState((prev) => ({ ...prev, isSuccess: false }));
    };

    // Sending the information filled in by the user
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormState((prev) => ({ ...prev, isLoading: true }));


        const emailError = validateField('email', email);
        if (emailError) {
            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                errors: { ...prev.errors, email: emailError },
            }));
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-reset-password`, { email });

            if (response.data.code === 200) {
                setFormState((prev) => ({
                    ...prev,
                    isSuccess: true,
                    email: ''
                }));
            } else {
                setFormState((prev) => ({
                    ...prev,
                    errorMessage: response.data.message || SignMessages.GENERIC.INTERNAL_ERROR,
                }));
            }
        } catch (error) {
            const message = error.response?.data?.message || SignMessages.GENERIC.INTERNAL_ERROR;
            setFormState((prev) => ({ ...prev, errorMessage: message }));
        }

        setFormState((prev) => ({
            ...prev,
            isLoading: false
        }));
    };

    return (
        <section className="sign">
            <div className="content-image" />
            <div className="content">
                <div className="logo" alt="logo" />
                <form className="form-login" onSubmit={handleSubmit} noValidate>
                    <AuthInput
                        icon={<Mail width={20} height={20} />}
                        label="E-mail"
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleValidation}
                        placeholder="Digite o seu e-mail"
                    />

                    <SignMessage
                        condition={errors.email}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errors.email}
                    />

                    <div className="form-buttons">
                        <Link className="sign-link" to="/" onClick={() => navigate('/')}>
                            Retornar para tela de login
                        </Link>
                        <button className="sign-btn" type="submit" disabled={isLoading}>
                            {isLoading ? <LoadingSpinner /> : 'Recuperar senha'}
                        </button>
                    </div>

                    <SignMessage
                        condition={errorMessage}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errorMessage}
                    />

                    <SignFeedback
                        condition={isSuccess}
                        feedback={"success"}
                        icon={<FeedbackSuccess width={28} height={28} color={'#FFF'} />}
                        title={SignMessages.SUCCESS_SEND_RESET_PASSWORD.TITLE}
                        message={SignMessages.SUCCESS_SEND_RESET_PASSWORD.MESSAGE}
                        button={"OK"}
                        onClose={handleCloseModal}
                    />
                </form>
            </div>
        </section>
    );
}

export default SendResetPassword;