// Import dependencies
import axios from 'axios';

// Sets the environment variables
const API_URL = process.env.REACT_APP_API_URL;

export const axiosForRequest = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    }
});