// Import react
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Import dependencies
import axios from 'axios';

// Import components
import AuthInput from '../components/AuthInput/AuthInput';
import SignMessage from '../components/SignMessage/SignMessage';
import SignFeedback from '../components/SignFeedback/SignFeedback';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';

// Import SVG icons
import { Error } from '../icons/Circle';
import { Password } from '../icons/Auth';
import { FeedbackSuccess } from '../icons/Feedback';

// Import messages
import { SignMessages } from '../messages/signMessages';

// Sets the environment variables
const API_URL = process.env.REACT_APP_API_URL;

function ResetPassword() {
    // Defines constants to be used
    const { token } = useParams();
    const navigate = useNavigate();

    // Confirms that the password reset token is valid
    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await axios.get(`${API_URL}/verify-token/${token}`);
                if (response.data.code !== 200) {
                    localStorage.setItem('passwordResetTokenError', response.data.message);
                    navigate("/")
                }
            } catch (error) {
                localStorage.setItem('passwordResetTokenError', error.response?.data?.message);
                navigate("/")
            }
        };

        verifyToken();
    }, [token, navigate]);

    // Treatment for component states
    const [formState, setFormState] = useState({
        isSuccess: false,
        isLoading: false,
        formValues: { password: '', confirmPassword: '' },
        errors: { password: '', confirmPassword: '' },
        errorMessage: '',
    });

    const { isSuccess, isLoading, formValues, errors, errorMessage } = formState;

    // Verification of the information filled in by the user
    const validateField = (name, value) => {
        const validators = {
            password: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.PASSWORD;
                if (value.length < 6) return SignMessages.INVALID_FILLING.PASSWORD;
                return '';
            },
            confirmPassword: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.CONFIRM_PASSWORD;
                if (value !== formValues.password) return SignMessages.INVALID_FILLING.MIS_MATCH_PASSWORD;
                return '';
            },
        };

        return validators[name] ? validators[name]() : '';
    };

    // Verification of the information filled in by the user
    const handleValidation = (event) => {
        const { name, value } = event.target;
        const errorMessage = validateField(name, value);

        setFormState((prev) => ({
            ...prev,
            formValues: { ...prev.formValues, [name]: value },
            errors: { ...prev.errors, [name]: errorMessage },
        }));
    };

    // Modal to close feedback
    const handleCloseModal = () => {
        setFormState((prev) => ({ ...prev, isSuccess: false }));
    };

    // Sends the request to change the user's password
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormState((prev) => ({ ...prev, isLoading: true }));

        const passwordError = validateField('password', formValues.password);
        const confirmPasswordError = validateField('confirmPassword', formValues.confirmPassword);

        if (passwordError || confirmPasswordError) {
            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                errors: {
                    password: passwordError || '',
                    confirmPassword: confirmPasswordError || '',
                }
            }));
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/reset-password`, {
                token,
                newPassword: formValues.password,
            });

            if (response.data.code === 200) {
                setFormState((prev) => ({
                    ...prev,
                    isSuccess: true,
                    isLoading: false,
                    errorMessage: '',
                    errors: {
                        password: '',
                        confirmPassword: '',
                    }
                }));
            } else {
                setFormState((prev) => ({
                    ...prev,
                    isLoading: false,
                    errorMessage: response?.data?.message || SignMessages.GENERIC.INTERNAL_ERROR
                }));
            }
        } catch (error) {
            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                errorMessage: error.response?.data?.message || SignMessages.GENERIC.INTERNAL_ERROR
            }));
        }
    };

    return (
        <section className="sign">
            <div className="content-image" />
            <div className="content">
                <div className="logo" alt="logo" />
                <form className="form-login" onSubmit={handleSubmit} noValidate>
                    <AuthInput
                        icon={<Password width={24} height={24} />}
                        label="Senha"
                        type="password"
                        name="password"
                        value={formValues.password}
                        onChange={handleValidation}
                        placeholder="Digite a sua nova senha"
                    />

                    <SignMessage
                        condition={errors.password}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errors.password}
                    />

                    <AuthInput
                        icon={<Password width={24} height={24} />}
                        label="Confirmar senha"
                        type="password"
                        name="confirmPassword"
                        value={formValues.confirmPassword}
                        onChange={handleValidation}
                        placeholder="Confirme a sua nova senha"
                    />

                    <SignMessage
                        condition={errors.confirmPassword}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errors.confirmPassword}
                    />

                    <div className="form-buttons" style={{ marginTop: '15px' }}>
                        <button className="sign-btn" type="submit">
                            {isLoading ? <LoadingSpinner /> : 'Alterar Senha'}
                        </button>
                    </div>

                    <SignMessage
                        condition={errorMessage}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errorMessage}
                    />

                    <SignFeedback
                        condition={isSuccess}
                        feedback={"success"}
                        icon={<FeedbackSuccess width={28} height={28} color={'#FFF'} />}
                        title={SignMessages.SUCCESS_RESET_PASSWORD.TITLE}
                        message={SignMessages.SUCCESS_RESET_PASSWORD.MESSAGE}
                        button={"Realizar login"}
                        onClose={handleCloseModal}
                        redirect={"/"}
                    />
                </form>
            </div>
        </section>
    );
}

export default ResetPassword;
