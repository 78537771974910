// Import react
import React from 'react';

// Import CSS
import './App.css';

// Import pages
import { Outlet } from "react-router-dom";

function App() {
    return (
        <div className="App">
            <Outlet />
        </div>
    );
}

export default App;