// Import react
import React from 'react';

// Import CSS
import './loadingSpinner.scss'

const LoadingSpinner = () => {
    return <div className="loading-spinner"></div>;
};

export default LoadingSpinner;
