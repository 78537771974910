export const ServicesMessages = {
    GENERIC: {
        INTERNAL_ERROR: "Ops, houve um problema em nosso servidores! Tente novamente"
    },
    REQUIRED_FIELDS: {
        FULL_NAME: "O campo nome completo é obrigatório",
        PHONE: "O campo telefone é obrigatório",
        DATE: "O campo data é obrigatório",
        TYPE_OF_SERVICE: "O campo tipo de serviço é obrigatório",
        VALUE: "O campo valor é obrigatório",
        DESCRIPTION: "O campo descrição é obrigatório",
        DURATION: "O campo tempo de duração é obrigatório",
        START_TIME: "Ops! Você deve buscar por horários disponíveis e posteriomente selecionar o melhor horário para o seu agendamento."
    },
    INVALID_FIELDS: {
        PHONE: "Telefone inválido",
        MIN_DATE: "Informe uma data igual ou posterior à data atual"
    }
}