// Import react
import React from "react";
import { useNavigate } from 'react-router-dom';

// Import CSS
import './navbar.scss'

// Import icons
import { NavbarCompress, NavbarExpand, NavbarLogout } from '../../icons/Navbar';

// Import components
import NavbarOption from '../Navbar/NavbarOption';
import NavbarOptionExpandable from '../Navbar/NavbarOptionExpandable';
import NavbarCollapsedOption from '../NavbarCollapsed/NavbarCollapsedOption';
import NavbarCollapsedOptionExpandable from '../NavbarCollapsed/NavbarCollapsedOptionExpandable';

// Import navbar options
import { NavbarSections } from '../../data/menu';

const Navbar = ({ userInfo, isExpanded, toggleNavbar }) => {
    // Defines constants to be used
    const navigate = useNavigate();

    // Function to Logout
    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('aboutUser');
        navigate('/');
    };

    return (
        <section id="navbar" className={isExpanded ? "expanded" : "collapsed"}>
            <button id="change-navbar" className={isExpanded ? "expanded" : "collapsed"} onClick={toggleNavbar}>
                {isExpanded ? <NavbarCompress /> : <NavbarExpand />}
            </button>

            <div className={isExpanded ? "content-navbar" : "content-navbar-collapsed"}>
                <nav id="global-navbar" className={isExpanded ? "global-navbar" : "global-navbar-collapsed"}>
                    <header id="navbar-logo" onClick={() => navigate("/dashboard")}>
                        <div className={isExpanded ? "logo" : "logo-collapsed"} />
                    </header>

                    <nav className={isExpanded ? "navbar-options" : "navbar-options-collapsed"}>
                        {isExpanded ? (
                            <div className="navbar-options-list">
                                {NavbarSections.map((section, index) => (
                                    <div key={index}>
                                        <h4 className="navbar-title">{section.titleSection}</h4>
                                        {section.options.map((option, optionIndex) => {
                                            return option.expandable ? (
                                                <NavbarOptionExpandable
                                                    key={optionIndex}
                                                    optionIcon={option.optionIcon}
                                                    optionName={option.optionName}
                                                    toggleName={option.optionToggleName}
                                                    subMenuOptions={option.subMenuOptions}
                                                />
                                            ) : (
                                                <NavbarOption
                                                    key={optionIndex}
                                                    optionIcon={option.optionIcon}
                                                    optionName={option.optionName}
                                                    optionPage={option.optionNavigate}
                                                />
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="navbar-options-list-collapsed">
                                {NavbarSections.map((section, index) => (
                                    <div key={index}>
                                        {section.options.map((option, optionIndex) => {
                                            return option.expandable ? (
                                                <NavbarCollapsedOptionExpandable
                                                    key={optionIndex}
                                                    toggleName={option.optionToggleName}
                                                    optionIcon={option.optionIcon}
                                                    optionName={option.optionName}
                                                    subMenuOptions={option.subMenuOptions}
                                                />
                                            ) : (
                                                <NavbarCollapsedOption
                                                    key={optionIndex}
                                                    optionIcon={option.optionIcon}
                                                    optionName={option.optionName}
                                                    optionNavigate={option.optionNavigate}
                                                />
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        )}
                    </nav>
                </nav>

                {isExpanded ? (
                    <footer id="navbar-footer">
                        <div className="navbar-user-data">
                            <div className="user-photo" />
                            <div className="user-info">
                                <h2 className="user-name">{userInfo.name}</h2>
                                <h3 className="user-position">{userInfo.rule}</h3>
                            </div>
                        </div>

                        <button id="logout" htmlFor="navbar-logout" style={{ height: '24px', cursor: 'pointer', backgroundColor: 'transparent' }} onClick={handleLogout}>
                            <NavbarLogout id="navbar-logout" width={'24px'} height={'24px'} color={'#F04438'} />
                        </button>
                    </footer>
                ) : (
                    <footer id="navbar-footer" style={{ gap: '0', width: 'auto', padding: '0' }}>
                        <div className="navbar-user-data">
                            <div className="user-photo" />
                        </div>
                    </footer>
                )}
            </div>
        </section>
    )
};

export default Navbar;