// Import react
import React from 'react';

// Import dependencies
import { clsx } from 'clsx';

// Import components
import Navbar from '../components/Navbar/Navbar';
import Content from '../components/Content/Content';

// Import hooks
import useGlobalState from '../hooks/useGlobalState';

function Dashboard() {
    const { aboutUser, isNavbarExpanded, toggleNavbar } = useGlobalState()

    return (
        <section id="home" className={clsx("global-content", { expanded: isNavbarExpanded, collapsed: !isNavbarExpanded })}>
            <Navbar userInfo={aboutUser} isExpanded={isNavbarExpanded} toggleNavbar={toggleNavbar} />

            <Content
                title={"Gerenciamento de Usuários"}
                subTitle={"Cadastro, edição e atualização de informação de todos os usuários do painel administrativo."}
                contentPage={"Pagina em desenvolvimento"}
            />
        </section>
    );
}

export default Dashboard;