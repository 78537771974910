// Import react
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Import CSS
import './navbarCollapsed.scss'

const NavbarCollapsedOption = ({ optionIcon, optionName, optionNavigate }) => {
    // Defines constants to be used
    const navigate = useNavigate();

    return (
        <nav className="navbar-collapsed-item" onClick={() => navigate(optionNavigate)}>
            <div className="navbar-icon-collapsed">{optionIcon}</div>
            <ol className="navbar-option-collapsed">{optionName}</ol>
        </nav>
    )
};

export default NavbarCollapsedOption;