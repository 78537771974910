// Import react
import React, { useEffect, useState } from 'react';

// Import dependencies
import axios from 'axios';
import { clsx } from 'clsx';

// Import CSS
import '../styles/form.scss'
import '../styles/routes/registerService.scss'

// Import components and pages
import Navbar from '../components/Navbar/Navbar';
import FormField from '../components/FormField/FormField';
import ImageCropper from '../components/CropImage/CropImage';
import ModalToCropImage from '../components/CropImage/ModalToCropImage';
import ModalOfActionOrFeedback from '../components/ModalOfActionOrFeedback/ModalOfActionOrFeedback';

// Import messages
import { SignMessages } from '../messages/signMessages';
import { ServicesMessages } from '../messages/servicesMessages';

// Import icons
import { Error, Success } from '../icons/Circle';
import { FeedbackError, FeedbackSuccess } from '../icons/Feedback';
import { TrashIcon } from '../icons/Common';

function RegisterService() {
    // Default body for headers
    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    }

    // Treatment for navbar state
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(true);
    const toggleNavbar = () => {
        setIsNavbarExpanded(!isNavbarExpanded);
    };

    // Treatment for user data states
    const [aboutUser, setAboutUser] = useState({
        name: '',
        rule: ''
    })

    // Set the user data
    useEffect(() => {
        const fetchUserData = async () => {
            const customerData = JSON.parse(localStorage.getItem('aboutUser'));

            setAboutUser((prev) => ({
                ...prev,
                name: customerData.name,
                rule: customerData.rule
            }));
        };

        fetchUserData();
    }, []);

    // Hook to monitor changes in window size
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1024) {
                setIsNavbarExpanded(false);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Function responsible for converting the value to decimal
    const convertToDecimal = (value) => {
        const sanitizedValue = value.replace(/[^\d,.-]/g, "").replace(/\./g, "");
        const decimalValue = sanitizedValue.replace(",", ".");

        return parseFloat(decimalValue);
    };

    // Treatment for component states
    const [formState, setFormState] = useState({
        formValues: {
            type: '',
            value: '',
            duration: '',
            description: '',
            isNew: false,
            isPromotion: false
        },
        errors: {
            type: '',
            value: '',
            duration: '',
            description: ''
        },
        errorMessage: '',
        isError: false,
        isSuccess: false,
        isLoading: false
    });

    const { formValues, errors, isError, isSuccess, isLoading } = formState;

    // Verification of the information filled in by the user
    const validateField = (name, value) => {
        const validators = {
            type: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.TYPE_OF_SERVICE;
                return '';
            },
            value: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.VALUE;
                if (convertToDecimal(value) === 0) return ServicesMessages.REQUIRED_FIELDS.VALUE;
                return '';
            },
            duration: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.DURATION;
                return '';
            },
            description: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.DESCRIPTION;
                return '';
            },
        };

        return validators[name] ? validators[name]() : '';
    };

    // Verification of the information filled in by the user
    const handleValidation = (event) => {
        const { name, value } = event.target;

        setFormState((prev) => {
            const errorMessage = validateField(name, value);
            const updatedFormValues = { ...prev.formValues, [name]: value };
            const updatedErrors = { ...prev.errors, [name]: errorMessage };

            return {
                ...prev,
                formValues: updatedFormValues,
                errors: updatedErrors,
            };
        });
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [imageName, setImageName] = useState(null);
    const [croppedFeedback, setCroppedFeedback] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImageName(file.name);
            const reader = new FileReader();

            reader.onload = () => {
                setSelectedImage(reader.result);
                setModalVisible(true);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleCrop = (blob) => {
        setCroppedImage(blob);
        setModalVisible(false);
        setCroppedFeedback(true);
    };

    const handleRemoveImage = () => {
        setCroppedImage(null);
        setCroppedFeedback(false);
        setImageName(null);
    };

    // Register a new service
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Defines that the form is being sent
        setFormState((prev) => ({
            ...prev,
            isLoading: true
        }));

        // Validations to check filled fields
        const typeError = validateField('type', formValues.type);
        const valueError = validateField('value', formValues.value);
        const durationError = validateField('duration', formValues.duration);
        const descriptionError = validateField('description', formValues.description);

        if (typeError || valueError || durationError || descriptionError) {
            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                errors: {
                    ...prev.errors,
                    type: typeError,
                    value: valueError,
                    duration: durationError,
                    description: descriptionError,
                },
            }));

            return;
        }

        try {
            const formData = new FormData();

            // Defines formData information
            formData.append('type', formValues.type);
            formData.append('value', convertToDecimal(formValues.value));
            formData.append('duration', formValues.duration);
            formData.append('description', formValues.description);
            formData.append('isNew', formValues.isNew ? 1 : 0);
            formData.append('isPromotion', formValues.isPromotion ? 1 : 0);

            // Obtém o arquivo da input
            if (croppedImage) {
                formData.append('image', croppedImage, imageName);
            }

            const request = {
                url: `${process.env.REACT_APP_API_URL}/register-service`,
                headers: headers,
                body: formData
            }

            await axios.post(request.url, request.body, request.headers)

            setFormState((prev) => ({
                ...prev,
                isSuccess: true,
                isLoading: false,
                formValues: {
                    type: '',
                    value: '',
                    duration: '',
                    description: '',
                    isNew: false,
                    isPromotion: false
                }
            }));

            setSelectedImage(null)
            setCroppedImage(null)
            setImageName(null)
            setCroppedFeedback(null)
        } catch (error) {
            const message = error.response?.data?.message || SignMessages.GENERIC.INTERNAL_ERROR;

            setFormState((prev) => ({
                ...prev,
                isError: true,
                isLoading: false,
                errors: {
                    ...prev.errors,
                    registration: message
                }
            }));
        }
    };

    // Closes the feedback modal
    const closeModal = () => {
        setFormState((prev) => ({
            ...prev,
            isError: false,
            isSuccess: false
        }));
    };

    return (
        <section id="home" className={clsx("global-content", { expanded: isNavbarExpanded, collapsed: !isNavbarExpanded })}>
            <Navbar userInfo={aboutUser} isExpanded={isNavbarExpanded} toggleNavbar={toggleNavbar} />

            <main id="content">
                <header className="content-title">
                    <h2>Cadastrar Serviço</h2>
                    <h3>Insira todas as informações necessárias para o serviço a ser oferecido para suas clientes</h3>
                </header>

                <div className="content-data">
                    <form id="register-service" className="custom-form" onSubmit={handleSubmit}>
                        <section className="form-section">
                            <h2 className="form-section-title">Informações</h2>
                            <div className="form-inputs">
                                <div className="custom-field service-image max-size">
                                    <span>Imagem de apresentação</span>
                                    <div>
                                        {!croppedImage ? (
                                            <>
                                                <label className="custom-input-file" htmlFor="image">Escolher imagem</label>
                                                <input type="file" id="image" maxLength={1} accept="image/*" onChange={handleImageChange} />
                                            </>
                                        ) : (
                                            <div className="image-preview">
                                                <img src={URL.createObjectURL(croppedImage)} alt="Prévia da imagem recortada" className="preview" />
                                                <button type="button" className="remove-image" onClick={handleRemoveImage}>
                                                    <TrashIcon width={'20px'} height={'20px'} color={'#FFF'}/>
                                                </button>
                                            </div>
                                        )}

                                        {croppedFeedback && (
                                            <p className="feedback-upload-image">
                                                <Success width={'18px'} height={'18px'} color={'#11a917'} />
                                                Upload realizado com sucesso!
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <FormField
                                    inputId="type"
                                    inputName="type"
                                    inputSize="size-480"
                                    inputType="text"
                                    inputLabel="Tipo de serviço"
                                    inputPlaceholder="Ex: Esmaltação em Gel nas Mãos"
                                    inputValue={formValues.type}
                                    inputOnChange={handleValidation}
                                    feedbackType={errors.type ? "error" : "success"}
                                    feedbackMessage={errors.type}
                                    feedbackIcon={errors.type ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <FormField
                                    inputCode="3"
                                    inputId="value"
                                    inputName="value"
                                    inputSize="size-220"
                                    inputType="text"
                                    inputLabel="Valor do serviço"
                                    inputPlaceholder="Valor cobrado pelo serviço"
                                    inputValue={formValues.value}
                                    inputOnChange={handleValidation}
                                    feedbackType={errors.value ? "error" : "success"}
                                    feedbackMessage={errors.value}
                                    feedbackIcon={errors.value ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <FormField
                                    inputId="duration"
                                    inputName="duration"
                                    inputSize="size-260"
                                    inputType="number"
                                    inputLabel="Duração do serviço"
                                    inputPlaceholder="Preencha o tempo em minutos"
                                    inputValue={formValues.duration}
                                    inputOnChange={handleValidation}
                                    feedbackType={errors.duration ? "error" : "success"}
                                    feedbackMessage={errors.duration}
                                    feedbackIcon={errors.duration ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <FormField
                                    inputId="description"
                                    inputName="description"
                                    inputSize="max-size"
                                    inputType="text"
                                    inputLabel="Descrição do serviço"
                                    inputPlaceholder="Ex: Secagem rápida e eficiente. Você sai pronta para curtir o seu dia!"
                                    inputMaxLength="120"
                                    inputValue={formValues.description}
                                    inputOnChange={handleValidation}
                                    feedbackType={errors.description ? "error" : "success"}
                                    feedbackMessage={errors.description}
                                    feedbackIcon={errors.description ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />


                            </div>
                        </section>

                        <section className="form-section">
                            <h2 className="form-section-title">Tags</h2>
                            <div className="form-inputs tags">
                                <div className="custom-field checkbox">
                                    <input
                                        type="checkbox"
                                        name="is-new"
                                        checked={formValues.isNew}
                                        onChange={(e) =>
                                            setFormState((prev) => ({
                                                ...prev,
                                                formValues: {
                                                    ...prev.formValues,
                                                    isNew: e.target.checked,
                                                }
                                            }))
                                        }
                                    />

                                    <label htmlFor="is-new">Novidade</label>
                                </div>

                                <div className="custom-field checkbox">
                                    <input
                                        type="checkbox"
                                        name="is-promotion"
                                        checked={formValues.isPromotion}
                                        onChange={(e) =>
                                            setFormState((prev) => ({
                                                ...prev,
                                                formValues: {
                                                    ...prev.formValues,
                                                    isPromotion: e.target.checked,
                                                }
                                            }))
                                        }
                                    />

                                    <label htmlFor="is-promotion">Promoção</label>
                                </div>
                            </div>
                        </section>

                        <section className="form-buttons">
                            <button type="submit" className="register-button" disabled={isLoading}>
                                {isLoading ? <div className="loading" /> : 'Cadastrar serviço'}
                            </button>
                        </section>
                    </form>
                </div>
            </main>

            {modalVisible && (
                <ModalToCropImage onClose={() => setModalVisible(false)}>
                    <ImageCropper image={selectedImage} onCrop={handleCrop} />
                </ModalToCropImage>
            )}

            <ModalOfActionOrFeedback
                conditionToBeVisible={isSuccess}
                typeOfModal={{ type: "feedback", colorFor: "success" }}

                data={{
                    icon: <FeedbackSuccess width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: `Serviço cadastrado com sucesso!`
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModal }}
            />

            <ModalOfActionOrFeedback
                conditionToBeVisible={isError}
                typeOfModal={{ type: "feedback", colorFor: "error" }}

                data={{
                    icon: <FeedbackError width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: SignMessages.GENERIC.INTERNAL_ERROR
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModal }}
            />
        </section>
    );
}

export default RegisterService;