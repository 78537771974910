// Import react
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Import components
import AuthInput from '../components/AuthInput/AuthInput';
import SignMessage from '../components/SignMessage/SignMessage';
import SignFeedback from '../components/SignFeedback/SignFeedback';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';

// Import services
import { login, validateToken } from '../services/auth';

// Import SVG icons
import { Error } from '../icons/Circle';
import { Mail, Password } from '../icons/Auth';
import { FeedbackError } from '../icons/Feedback';

// Import messages
import { SignMessages } from '../messages/signMessages';

function Login() {
    // Defines constants to be used
    const navigate = useNavigate();

    // Treatment for component states
    const [formState, setFormState] = useState({
        isTokenError: false,
        isLoading: false,
        formValues: { email: '', password: '' },
        errors: { email: '', password: '' },
        errorMessage: '',
        tokenErrorMessage: ''
    });

    const {
        isTokenError,
        isLoading,
        formValues,
        errors,
        errorMessage,
        tokenErrorMessage
    } = formState;

    // Check for a password reset error
    useEffect(() => {
        const tokenError = localStorage.getItem('passwordResetTokenError');

        if (tokenError) {
            setFormState((prev) => ({
                ...prev,
                isTokenError: true,
                tokenErrorMessage: tokenError
            }));

            localStorage.removeItem('passwordResetTokenError');
        }
    }, []);

    // Checking the user's session when accessing the login screen
    useEffect(() => {
        const checkAuthToken = async () => {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
                try {
                    await validateToken(authToken);
                    navigate('/dashboard');
                } catch (error) {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('aboutUser');
                }
            }
        };

        checkAuthToken();
    }, [navigate]);

    // Verification of the information filled in by the user
    const validateField = (name, value) => {
        const validators = {
            email: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.EMAIL;
                if (!/\S+@\S+\.\S+/.test(value)) return SignMessages.INVALID_FILLING.EMAIL;
                return '';
            },
            password: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.PASSWORD;
                return '';
            },
        };
        return validators[name] ? validators[name]() : '';
    };

    // Verification of the information filled in by the user
    const handleValidation = (event) => {
        const { name, value } = event.target;

        setFormState((prev) => {
            const errorMessage = validateField(name, value);
            const updatedFormValues = { ...prev.formValues, [name]: value };
            const updatedErrors = { ...prev.errors, [name]: errorMessage };

            return {
                ...prev,
                formValues: updatedFormValues,
                errors: updatedErrors,
            };
        });
    };

    // Authenticates the user
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormState((prev) => ({ ...prev, isLoading: true }));

        const emailError = validateField('email', formValues.email);
        const passwordError = validateField('password', formValues.password);

        setFormState((prev) => ({
            ...prev,
            errors: {
                email: emailError,
                password: passwordError,
            },
        }));

        if (!emailError && !passwordError) {
            try {
                await login(formValues.email, formValues.password);
                navigate('/dashboard');
            } catch (error) {
                setFormState((prev) => ({
                    ...prev,
                    errorMessage: error.message,
                }));
            }
        }

        setFormState((prev) => ({ ...prev, isLoading: false }));
    };

    // Modal to close feedback
    const handleCloseModal = () => {
        setFormState((prev) => ({ ...prev, isTokenError: false }));
    };

    return (
        <section className="sign">
            <div className="content-image" />
            <div className="content">
                <div className="logo" alt="logo" />
                <form className="form-login" onSubmit={handleSubmit} noValidate>
                    <AuthInput
                        icon={<Mail width={20} height={20} />}
                        label="E-mail"
                        type="email"
                        name="email"
                        value={formValues.email}
                        onChange={handleValidation}
                        placeholder="Digite o seu e-mail"
                    />

                    <SignMessage
                        condition={errors.email}
                        type="error"
                        icon={<Error width={19} height={19} color="#ef6464" />}
                        message={errors.email}
                    />

                    <AuthInput
                        icon={<Password width={24} height={24} />}
                        label="Senha"
                        type="password"
                        name="password"
                        value={formValues.password}
                        onChange={handleValidation}
                        placeholder="Digite a sua senha"
                    />

                    <SignMessage
                        condition={errors.password}
                        type="error"
                        icon={<Error width={19} height={19} color="#ef6464" />}
                        message={errors.password}
                    />

                    <div className="form-buttons">
                        <Link className="sign-link" to="/esqueci-minha-senha" onClick={() => navigate('/')}>
                            Esqueci minha senha
                        </Link>
                        <button className="sign-btn" type="submit" disabled={isLoading}>
                            {isLoading ? <LoadingSpinner /> : 'Entrar'}
                        </button>
                    </div>

                    <SignMessage
                        condition={errorMessage}
                        type="error"
                        icon={<Error width={19} height={19} color="#ef6464" />}
                        message={errorMessage}
                    />

                    <SignFeedback
                        condition={isTokenError}
                        feedback={"error"}
                        icon={<FeedbackError width={28} height={28} color={'#FFF'} />}
                        title={SignMessages.ERROR_RESET_PASSWORD.TITLE}
                        message={tokenErrorMessage}
                        button={"OK"}
                        onClose={handleCloseModal}
                    />
                </form>
            </div>
        </section>
    );
}

export default Login;