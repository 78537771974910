// Import react
import React from 'react';

// Import SCSS
import './modalToCropImage.scss'

const ModalToCropImage = ({ children, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <button onClick={onClose}>Fechar</button>
                </div>

                <div className="modal-crop">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalToCropImage;
