// Import react
import React from 'react';

const Content = ({ title, subTitle, contentPage }) => {
    return (
        <main id="content">
            <header className="content-title">
                <h2>{title}</h2>
                <h3>{subTitle}</h3>
            </header>

            <div className="content-data">
                {contentPage}
            </div>
        </main>
    )
};

export default Content;