// Import react and dependencies
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Import dependencies
import axios from 'axios';

// Import components
import AuthInput from '../components/AuthInput/AuthInput';
import SignMessage from '../components/SignMessage/SignMessage';
import SignFeedback from '../components/SignFeedback/SignFeedback';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';

// Import services
import { validateToken } from '../services/auth';

// Import SVG icons
import { Error } from '../icons/Circle';
import { FeedbackSuccess } from '../icons/Feedback';
import { Mail, Password, User } from '../icons/Auth';

// Import messages
import { SignMessages } from '../messages/signMessages';

function Register() {
    // Defines constants to be used
    const navigate = useNavigate();

    // Treatment for component states
    const [formState, setFormState] = useState({
        formValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        errors: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            registration: '',
        },
        errorMessage: '',
        isSuccess: false,
        isLoading: false
    });

    const { formValues, errors, isSuccess, isLoading } = formState;

    // Checking the user's session when accessing the register page
    useEffect(() => {
        const checkAuthToken = async () => {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
                try {
                    await validateToken(authToken);
                    navigate('/dashboard');
                } catch {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('aboutUser');
                }
            }
        };
        checkAuthToken();
    }, [navigate]);

    // Verification of the information filled in by the user
    const validateField = (name, value) => {
        const validators = {
            name: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.NAME;
                if (value.length < 3) return SignMessages.INVALID_FILLING.NAME;
                return '';
            },
            email: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.EMAIL;
                if (!/\S+@\S+\.\S+/.test(value)) return SignMessages.INVALID_FILLING.EMAIL;
                return '';
            },
            password: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.PASSWORD;
                if (value.length < 6) return SignMessages.INVALID_FILLING.PASSWORD;
                return '';
            },
            confirmPassword: () => {
                if (!value) return SignMessages.REQUIRED_FIELDS.CONFIRM_PASSWORD;
                if (value !== formValues.password) return SignMessages.INVALID_FILLING.MIS_MATCH_PASSWORD;
                return '';
            },
        };
        return validators[name] ? validators[name]() : '';
    };

    // Verification of the information filled in by the user
    const handleValidation = (event) => {
        const { name, value } = event.target;
        setFormState((prev) => ({
            ...prev,
            formValues: { ...prev.formValues, [name]: value },
            errorMessage: name === 'email' ? '' : prev.errorMessage,
            errors: {
                ...prev.errors,
                [name]: validateField(name, value),
                registration: '',
            }
        }));
    };

    // Verification of the information filled in by the user
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormState((prev) => ({ ...prev, isLoading: true }));

        const nameError = validateField('name', formValues.name);
        const emailError = validateField('email', formValues.email);
        const passwordError = validateField('password', formValues.password);
        const confirmPasswordError = validateField('confirmPassword', formValues.confirmPassword);

        if (formValues.password === formValues.confirmPassword) {
            setFormState((prev) => ({
                ...prev,
                errors: {
                    ...prev.errors,
                    confirmPassword: '',
                },
            }));
        }

        if (nameError || emailError || passwordError || confirmPasswordError) {
            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                errors: {
                    ...prev.errors,
                    name: nameError,
                    email: emailError,
                    password: passwordError,
                    confirmPassword: confirmPasswordError,
                },
            }));
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                name: formValues.name,
                email: formValues.email,
                password: formValues.password,
                creationDate: new Date().toISOString().slice(0, 19).replace('T', ' '),
            });

            if (response.data.code === 200) {
                setFormState((prev) => ({
                    ...prev,
                    isSuccess: true,
                    isLoading: false,
                    errorMessage: '',
                    errors: {
                        name: '',
                        email: '',
                        password: '',
                        confirmPassword: '',
                    }
                }));
            } else {
                setFormState((prev) => ({
                    ...prev,
                    isLoading: false,
                    errors: { ...prev.errors, registration: response.data.message || SignMessages.GENERIC.INTERNAL_ERROR },
                }));
            }
        } catch (error) {
            const message = error.response?.data?.message || SignMessages.GENERIC.INTERNAL_ERROR;

            setFormState((prev) => ({
                ...prev,
                isLoading: false,
                errors: { ...prev.errors, registration: message },
            }));
        }
    };

    // Modal to close feedback
    const handleCloseModal = () => {
        setFormState((prev) => ({ ...prev, isSuccess: false }));
    };

    return (
        <section className="sign">
            <div className="content-image" />
            <div className="content">
                <div className="logo" alt="logo" />
                <form className="form-login" onSubmit={handleSubmit} noValidate>
                    <AuthInput
                        icon={<User width={24} height={24} />}
                        label="Nome"
                        type="text"
                        name="name"
                        value={formValues.name}
                        onChange={handleValidation}
                        placeholder="Digite o seu nome"
                    />

                    <SignMessage
                        condition={errors.name}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errors.name}
                    />

                    <AuthInput
                        icon={<Mail width={20} height={20} />}
                        label="Email"
                        type="email"
                        name="email"
                        value={formValues.email}
                        onChange={handleValidation}
                        placeholder="Digite o seu e-mail"
                    />

                    <SignMessage
                        condition={errors.email}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errors.email}
                    />

                    <AuthInput
                        icon={<Password width={24} height={24} />}
                        label="Senha"
                        type="password"
                        name="password"
                        value={formValues.password}
                        onChange={handleValidation}
                        placeholder="Digite a sua senha"
                    />

                    <SignMessage
                        condition={errors.password}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errors.password}
                    />

                    <AuthInput
                        icon={<Password width={24} height={24} />}
                        label="Confirmar senha"
                        type="password"
                        name="confirmPassword"
                        value={formValues.confirmPassword}
                        onChange={handleValidation}
                        placeholder="Confirme a sua nova senha"
                    />

                    <SignMessage
                        condition={errors.confirmPassword}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errors.confirmPassword}
                    />

                    <div className="form-buttons">
                        <Link className="sign-link" to="/" onClick={() => navigate('/')}>
                            Retornar para tela de login
                        </Link>
                        <button className="sign-btn" type="submit" disabled={isLoading}>
                            {isLoading ? <LoadingSpinner /> : 'Cadastrar usuário'}
                        </button>
                    </div>

                    <SignMessage
                        condition={errors.registration}
                        type="error"
                        icon={<Error width={19} height={19} color={'#ef6464'} />}
                        message={errors.registration}
                    />

                    <SignFeedback
                        condition={isSuccess}
                        feedback={"success"}
                        icon={<FeedbackSuccess width={28} height={28} color={'#FFF'} />}
                        title={SignMessages.SUCCESS_SIGN_UP.TITLE}
                        message={SignMessages.SUCCESS_SIGN_UP.MESSAGE}
                        button={"OK"}
                        onClose={handleCloseModal}
                        redirect={"/"}
                    />
                </form>
            </div>
        </section>
    );
}

export default Register;