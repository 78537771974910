// Import react
import React, { useState } from 'react';

// Import dependencies
import { clsx } from 'clsx';

// Import CSS
import '../styles/form.scss'
import '../styles/routes/scheduleAppointment.scss'

// Import components and pages
import Navbar from '../components/Navbar/Navbar';
import FormField from '../components/FormField/FormField';
import ModalOfActionOrFeedback from '../components/ModalOfActionOrFeedback/ModalOfActionOrFeedback';

// Import services
import { axiosForRequest } from '../services/axios.config';

// Import messages
import { SignMessages } from '../messages/signMessages';
import { ServicesMessages } from '../messages/servicesMessages';

// Import icons
import { Error, Success } from '../icons/Circle';
import { FeedbackError, FeedbackSuccess } from '../icons/Feedback';

// Import hooks
import useGlobalState from '../hooks/useGlobalState';
import { useListService } from '../hooks/ListService';

// Import functions
import { generateEndTime, generateTodayDate } from '../functions/generates';
import { convertDateToBrazilian } from '../functions/converts';

function RegisterAppointment() {
    // Defines constants to be used
    const { aboutUser, isNavbarExpanded, toggleNavbar } = useGlobalState()
    const { ourServices } = useListService()

    // Treatment for component states
    const [searchTimes, setSearchTimes] = useState({
        availableTimes: [],
        status: {
            isLoading: false,
            isError: {
                status: false,
                message: ''
            },
            isSuccess: {
                status: false,
                message: ''
            },
            isSoldOut: {
                status: false
            }
        }
    })

    const [appointment, setAppointment] = useState({
        aboutAppointment: {
            name: '',
            phone: '',
            duration: '',
            startTime: '',
            typeOfService: '',
            dateOfService: '',
            formattedDateOfService: ''
        },
        formErrors: {
            name: '',
            phone: '',
            typeOfService: '',
            dateOfService: '',
            startTime: ''
        },
        status: {
            isError: {
                status: false,
                message: ''
            },
            isLoading: {
                status: false,
                message: ''
            },
            isSuccess: {
                status: false,
                message: ''
            }
        }
    })

    const { aboutAppointment, formErrors, status } = appointment;

    const validateField = (name, value) => {
        const validators = {
            name: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.FULL_NAME;
                return '';
            },
            phone: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.PHONE;
                if (value.length < 14) return ServicesMessages.INVALID_FIELDS.PHONE
                return '';
            },
            typeOfService: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.TYPE_OF_SERVICE;
                return '';
            },
            dateOfService: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.DATE;
                if (value < generateTodayDate()) return ServicesMessages.INVALID_FIELDS.MIN_DATE;
                return '';
            },
            startTime: () => {
                if (!value) return ServicesMessages.REQUIRED_FIELDS.START_TIME
            }
        };

        return validators[name] ? validators[name]() : '';
    };

    // Verification of the information filled in by the user
    const handleValidation = (event) => {
        const { name, value } = event.target;

        setAppointment((prev) => {
            const errorMessage = validateField(name, value);
            const updatedFormValues = { ...prev.aboutAppointment, [name]: value };
            const updatedErrors = { ...prev.formErrors, [name]: errorMessage };

            return {
                ...prev,
                aboutAppointment: updatedFormValues,
                formErrors: updatedErrors,
            };
        });

        const serviceSelected = ourServices.data.types.find(
            (service) => service.type === value
        );

        if (serviceSelected) {
            setAppointment((prev) => ({
                ...prev,
                aboutAppointment: {
                    ...prev.aboutAppointment,
                    duration: serviceSelected.duration,
                },
            }));
        }

        if (name === "dateOfService" || name === "typeOfService") {
            setSearchTimes((prev) => ({
                ...prev,
                availableTimes: [],
                status: {
                    ...prev.status,
                    isSoldOut: {
                        status: false
                    }
                }
            }));

            setAppointment((prev) => ({
                ...prev,
                aboutAppointment: {
                    ...prev.aboutAppointment,
                    startTime: '',
                    formattedDateOfService: ''
                }
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Sets the loading status of the information
        setAppointment((prev) => ({
            ...prev,
            status: {
                ...prev.status,
                isLoading: {
                    status: true
                }
            }
        }));

        const formError = {
            name: validateField('name', aboutAppointment.name),
            phone: validateField('phone', aboutAppointment.phone),
            typeOfService: validateField('typeOfService', aboutAppointment.typeOfService),
            dateOfService: validateField('dateOfService', aboutAppointment.dateOfService),
            startTime: validateField('startTime', aboutAppointment.startTime),
        }

        if (formError.name || formError.phone || formError.typeOfService || formError.dateOfService) {
            setAppointment((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: {
                        ...prev.isLoading,
                        status: false
                    },
                },
                formErrors: {
                    ...prev.formErrors,
                    name: formError.name,
                    phone: formError.phone,
                    typeOfService: formError.typeOfService,
                    dateOfService: formError.dateOfService
                },
            }));

            return;
        }

        if (formError.startTime) {
            setAppointment((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: {
                        ...prev.isLoading,
                        status: false
                    }
                },
                formErrors: {
                    ...prev.formErrors,
                    startTime: formError.startTime
                }
            }));

            setSearchTimes((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isError: {
                        status: true,
                        message: formError.startTime
                    }
                }
            }));

            return;
        }

        try {
            await axiosForRequest.post('/schedule-appointment', {
                customer: {
                    name: aboutAppointment.name,
                    phone: aboutAppointment.phone,
                    service: aboutAppointment.typeOfService
                },
                schedule: {
                    date: aboutAppointment.dateOfService,
                    startTime: aboutAppointment.startTime,
                    endTime: generateEndTime(aboutAppointment.startTime, aboutAppointment.duration)
                }
            })

            setAppointment((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: {
                        ...prev.isLoading,
                        status: false
                    },
                    isSuccess: {
                        status: true,
                        message: 'Agendamento realizado com sucesso!'
                    }
                }
            }));

            setSearchTimes((prev) => ({
                ...prev,
                availableTimes: []
            }));
        } catch (error) {
            const message = error.response?.data?.message || SignMessages.GENERIC.INTERNAL_ERROR;

            setAppointment((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: {
                        ...prev.isLoading,
                        status: false
                    },
                    isError: {
                        status: true,
                        message: message
                    }
                }
            }));
        }
    }

    const handleSearchTimes = async (event) => {
        event.preventDefault();

        setSearchTimes((prev) => ({
            status: {
                ...prev.status,
                isLoading: true
            }
        }))

        const formError = {
            typeOfService: validateField('typeOfService', aboutAppointment.typeOfService),
            dateOfService: validateField('dateOfService', aboutAppointment.dateOfService),
        }

        if (formError.typeOfService || formError.dateOfService) {
            setAppointment((prev) => ({
                ...prev,
                formErrors: {
                    ...prev.formErrors,
                    typeOfService: formError.typeOfService,
                    dateOfService: formError.dateOfService,
                },
            }));

            setSearchTimes((prev) => ({
                status: {
                    ...prev.status,
                    isLoading: false
                }
            }))

            return;
        }

        try {
            const response = await axiosForRequest.post('/search-available-times', {
                schedule: {
                    date: aboutAppointment.dateOfService,
                    duration: aboutAppointment.duration
                }
            })

            if (response.data.availableTimes && response.data.availableTimes.length === 0) {
                setSearchTimes((prev) => ({
                    ...prev,
                    status: {
                        ...prev.status,
                        isSoldOut: {
                            status: true
                        }
                    }
                }))

                setAppointment((prev) => ({
                    ...prev,
                    aboutAppointment: {
                        ...prev.aboutAppointment,
                        formattedDateOfService: convertDateToBrazilian(aboutAppointment.dateOfService)
                    }
                }))
            }

            setSearchTimes((prev) => ({
                ...prev,
                availableTimes: response.data.availableTimes,
                status: {
                    ...prev.status,
                    isLoading: false
                }
            }))

        } catch (error) {
            const message = error.response?.data?.message || SignMessages.GENERIC.INTERNAL_ERROR;

            setSearchTimes((prev) => ({
                status: {
                    ...prev.status,
                    isLoading: false,
                    isError: {
                        status: true,
                        message: message
                    }
                }
            }))
        }
    }

    const handleTimeChange = (event) => {
        setAppointment((prev) => ({
            ...prev,
            aboutAppointment: {
                ...prev.aboutAppointment,
                startTime: event.target.value
            }
        }))
    };

    // Closes the feedback modal
    const closeModal = () => {
        setAppointment((prev) => ({
            ...prev,
            aboutAppointment: {
                name: '',
                phone: '',
                typeOfService: '',
                dateOfService: ''
            },
            status: {
                ...prev.status,
                isSuccess: {
                    ...prev.isSuccess,
                    status: false
                },
                isError: {
                    ...prev.isError,
                    status: false
                }
            }
        }));
    };

    // Closes the feedback modal
    const closeModalToScheduleError = () => {
        setSearchTimes((prev) => ({
            ...prev,
            status: {
                ...prev.status,
                isError: {
                    ...prev.isError,
                    status: false
                }
            }
        }));
    };

    return (
        <section id="schedule-appointment" className={clsx("global-content", { expanded: isNavbarExpanded, collapsed: !isNavbarExpanded })}>
            <Navbar userInfo={aboutUser} isExpanded={isNavbarExpanded} toggleNavbar={toggleNavbar} />

            <main id="content">
                <header className="content-title">
                    <h2>Agendar atendimento</h2>
                    <h3>Insira as informações abaixo para agendar um atendimento</h3>
                </header>

                <div className="content-data">
                    <form id="register-service" className="custom-form" onSubmit={handleSubmit}>
                        <section className="form-section">
                            <h2 className="form-section-title">Dados pessoais</h2>
                            <div className="form-inputs">
                                <FormField
                                    inputId="name"
                                    inputName="name"
                                    inputSize="size-780"
                                    inputType="text"
                                    inputLabel="Nome completo"
                                    inputPlaceholder="Ex: Maria Fernada de Carlos Assunção"
                                    inputValue={aboutAppointment.name}
                                    inputOnChange={handleValidation}
                                    feedbackType={formErrors.name ? "error" : "success"}
                                    feedbackMessage={formErrors.name}
                                    feedbackIcon={formErrors.name ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <FormField
                                    inputCode={1}
                                    inputId="phone"
                                    inputName="phone"
                                    inputSize="size-200"
                                    inputType="text"
                                    inputLabel="Telefone"
                                    inputPlaceholder="Ex: (18) 99100-0000"
                                    inputValue={aboutAppointment.phone}
                                    inputOnChange={handleValidation}
                                    feedbackType={formErrors.phone ? "error" : "success"}
                                    feedbackMessage={formErrors.phone}
                                    feedbackIcon={formErrors.phone ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />
                            </div>
                        </section>

                        <section className="form-section">
                            <h2 className="form-section-title">Dados do agendamento</h2>
                            <div className="form-inputs">
                                <FormField
                                    inputId="typeOfService"
                                    inputName="typeOfService"
                                    inputSize="size-540"
                                    inputType="select"
                                    inputLabel="Tipo de serviço"
                                    inputValue={aboutAppointment.typeOfService}
                                    inputOnChange={handleValidation}
                                    selectOptions={ourServices.data.types}
                                    feedbackType={formErrors.typeOfService ? "error" : "success"}
                                    feedbackMessage={formErrors.typeOfService}
                                    feedbackIcon={formErrors.typeOfService ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <FormField
                                    inputId="dateOfService"
                                    inputName="dateOfService"
                                    inputSize="size-220"
                                    inputType="date"
                                    inputLabel="Data do agendamento"
                                    inputMinDate={true}
                                    inputValue={aboutAppointment.dateOfService}
                                    inputOnChange={handleValidation}
                                    feedbackType={formErrors.dateOfService ? "error" : "success"}
                                    feedbackMessage={formErrors.dateOfService}
                                    feedbackIcon={formErrors.dateOfService ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                />

                                <button className="custom-button" onClick={handleSearchTimes} disabled={searchTimes.status.isLoading}>
                                    {searchTimes.status.isLoading ? <div className="loading" /> : 'BUSCAR HORÁRIOS'}
                                </button>
                            </div>

                            {searchTimes.availableTimes && searchTimes.availableTimes.length > 0 ? (
                                <div className="times-options">
                                    {searchTimes.availableTimes.map((time, index) => (
                                        <div className="custom-radio" key={index}>
                                            <label className="custom-label">
                                                <input
                                                    className="custom-input-radio"
                                                    type="radio"
                                                    id="startTime"
                                                    name="startTime"
                                                    value={time}
                                                    onChange={handleTimeChange}
                                                />
                                                <span className="custom-check">{time}</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                searchTimes.status.isSoldOut.status && (
                                    <div className="feedback-times-options">
                                        <Error className="icon" width={20} height={20} />
                                        <p>
                                            Os horários para o dia <b>{aboutAppointment.formattedDateOfService}</b> estão esgotados. Por gentileza, selecione uma nova data.
                                        </p>
                                    </div>
                                )
                            )}
                        </section>

                        <section className="form-buttons">
                            <button type="submit" className="register-button" disabled={status.isLoading.status}>
                                {status.isLoading.status ? <div className="loading" /> : 'AGENDAR ATENDIMENTO'}
                            </button>
                        </section>
                    </form>
                </div>
            </main>

            <ModalOfActionOrFeedback
                conditionToBeVisible={status.isSuccess.status}
                typeOfModal={{ type: "feedback", colorFor: "success" }}

                data={{
                    icon: <FeedbackSuccess width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: status.isSuccess.message
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModal }}
            />

            <ModalOfActionOrFeedback
                conditionToBeVisible={status.isError.status}
                typeOfModal={{ type: "feedback", colorFor: "error" }}

                data={{
                    icon: <FeedbackError width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: SignMessages.GENERIC.INTERNAL_ERROR
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModal }}
            />

            <ModalOfActionOrFeedback
                conditionToBeVisible={searchTimes.status.isError.status}
                typeOfModal={{ type: "feedback", colorFor: "error" }}

                data={{
                    icon: <FeedbackError width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: searchTimes.status.isError.message
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModalToScheduleError }}
            />
        </section>
    );
}

export default RegisterAppointment;