// Import React
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals.js';

// Import CSS
import './index.css';
import './styles/main.scss'
import './styles/routes/sign.scss'

// Import APP
import App from './App.jsx';

// Config router
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

// Import Routes
import Form from './routes/Forms.jsx';
import Login from './routes/Login.jsx';
import Register from './routes/Register.jsx';
import Dashboard from './routes/Dashboard.jsx';
import ResetPassword from './routes/ResetPassword.jsx';
import SendResetPassword from './routes/SendResetPassword.jsx';
import RegisterService from './routes/RegisterService.jsx';
import ListService from './routes/ListService.jsx';
import EditService from './routes/EditService.jsx';
import ScheduleAppointment from './routes/ScheduleAppointment.jsx';

// Import Components
import PrivateRoute from './components/Private/PrivateRoute.jsx';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Navigate to="/" />,
        children: [
            {
                path: "/",
                element: <Login />
            },
            {
                path: "dashboard",
                element: <PrivateRoute element={<Dashboard />} />
            },
            {
                path: "cadastrar-agendamento",
                element: <PrivateRoute element={<ScheduleAppointment />} />
            },
            {
                path: "cadastrar-servico",
                element: <PrivateRoute element={<RegisterService />} />
            },
            {
                path: "gerenciar-servicos",
                element: <PrivateRoute element={<ListService />} />
            },
            {
                path: "editar-servico",
                element: <PrivateRoute element={<EditService />} />
            },
            {
                path: "form",
                element: <PrivateRoute element={<Form />} />
            },
            {
                path: "esqueci-minha-senha",
                element: <SendResetPassword />
            },
            {
                path: "cadastrar-usuario",
                element: <Register />
            },
            {
                path: "alterar-senha/:token",
                element: <ResetPassword />
            }
        ]
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    // <React.StrictMode>
        <RouterProvider router={router} />
    // </React.StrictMode>
);

reportWebVitals();