import { useState, useEffect } from 'react';

const useGlobalState = () => {
    // Tratamento para os dados do usuário
    const [aboutUser, setAboutUser] = useState({
        name: '',
        rule: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            const customerData = JSON.parse(localStorage.getItem('aboutUser'));
            if (customerData) {
                setAboutUser({
                    name: customerData.name,
                    rule: customerData.rule
                });
                console.log(customerData.name);
            }
        };

        fetchUserData();
    }, []);

    // Tratamento para o estado do navbar
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(true);
    const toggleNavbar = () => {
        setIsNavbarExpanded(!isNavbarExpanded);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1024) {
                setIsNavbarExpanded(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        aboutUser,
        isNavbarExpanded,
        toggleNavbar,
    };
};

export default useGlobalState;