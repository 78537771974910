import {
    NavbarIconActivities,
    NavbarIconCalendar,
    NavbarIconMoney
} from "../icons/Navbar.jsx";

export const NavbarSections = [
    {
        titleSection: "Minha empresa",
        options: [
            {
                optionIcon: <NavbarIconCalendar width={'22px'} height={'22px'} />,
                optionName: "Cadastrar agendamento",
                optionNavigate: "/cadastrar-agendamento"
            },
            {
                expandable: true,
                optionToggleName: "data-base",
                optionIcon: <NavbarIconActivities width={'22px'} height={'22px'} />,
                optionName: "Serviços",
                subMenuOptions: [
                    {
                        optionName: "Cadastrar serviço",
                        optionNavigate: "/cadastrar-servico"
                    },
                    {
                        optionName: "Gerenciar serviços",
                        optionNavigate: "/gerenciar-servicos"
                    }
                ]
            }
        ],
    },
    {
        titleSection: "Pagamentos",
        options: [
            {
                optionIcon: <NavbarIconMoney width={'22px'} height={'22px'} />,
                optionName: "Dados de pagamento",
                optionNavigate: "/dashboard"
            }
        ],
    }
];

export const NavbarPathnames = {
    registerService: '/cadastrar-servico',
    listServices: '/gerenciar-servicos',
    editService: '/editar-servico',
    registerAppointment: 'cadastrar-agendamento'
}