// Import react
import React, { useEffect, useState } from 'react';

// Import dependencies
import { clsx } from 'clsx';

// Import components
import Navbar from '../components/Navbar/Navbar';
import Content from '../components/Content/Content';

// Sets the environment variables
const API_URL = process.env.REACT_APP_API_URL;

function Dashboard() {
    // Treatment for component states
    const [userName, setUserName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [error, setError] = useState('');

    // Searches for user data
    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('authToken');

            try {
                const response = await fetch(`${API_URL}/me`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                });

                if (!response.ok) {
                    throw new Error('Erro ao buscar dados do usuário');
                }

                const data = await response.json();
                setUserName(data.user.name);
                setJobTitle(data.user.permission)
            } catch (error) {
                console.error(error);
                setError('Não foi possível buscar os dados do usuário.');
            }
        };

        fetchUserData();
    }, []);

    // Defines user info
    const userInfo = {
        name: userName,
        jobTitle: jobTitle,
        error: error
    }

    // Treatment for component states
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(true);
    const toggleNavbar = () => {
        setIsNavbarExpanded(!isNavbarExpanded);
    };

    // Hook to monitor changes in window size
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1024) {
                setIsNavbarExpanded(false);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <section id="home" className={clsx("global-content", { expanded: isNavbarExpanded, collapsed: !isNavbarExpanded })}>
            <Navbar userInfo={userInfo} isExpanded={isNavbarExpanded} toggleNavbar={toggleNavbar} />

            <Content
                title={"Campos de Formulário"}
                subTitle={"Cadastro, edição e atualização de informação de todos os usuários do painel administrativo."}
                contentPage={"Aqui importa o conteúdo da página de form"}
            />
        </section>
    );
}

export default Dashboard;